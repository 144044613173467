<template>
    <div class="modal">
      <div class="modal-main">
          <div class="modal-header">
              <i @click="modalShow" class="icon-close"
                      ><svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L8.75 7.68934L16.2197 0.21967C16.5126 -0.0732233 16.9874 -0.0732233 17.2803 0.21967C17.5732 0.512563 17.5732 0.987437 17.2803 1.28033L9.81066 8.75L17.2803 16.2197C17.5732 16.5126 17.5732 16.9874 17.2803 17.2803C16.9874 17.5732 16.5126 17.5732 16.2197 17.2803L8.75 9.81066L1.28033 17.2803C0.987437 17.5732 0.512563 17.5732 0.21967 17.2803C-0.0732233 16.9874 -0.0732233 16.5126 0.21967 16.2197L7.68934 8.75L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967Z"
                          fill="black"
                          /></svg
                      ></i>
          </div>
          <div class="modal-body">
            <div class="modal_header_title">
              <h2>Сохраняйте наиболее часто используемые</h2>
              <div class="modal_toggle_body">
                  <!-- <div class="modal_toggle" v-for="(item,num) in el.array" :key="num">
                    <input :id="num+''+index" type="checkbox">
                    <label :for="num+''+index">{{item?.title}}</label>
                  </div> -->
                </div>
              </div>
              <div class="modal_toggle_body" v-for="(el,index) in $store.state.allSection.menu.menuDocument" :key="index">
                <div class="modal_toggle_header">
                  <b>{{el?.name?.language_ru}}</b>
                </div>
                <div v-show="el.category!='section'" class="modal_toggle">
                  <input class="cursor-pointer" v-model="el.favourite" :id="el?.name?.language_ru+''+index" type="checkbox">
                  <label class="cursor-pointer" :for="el?.name?.language_ru+''+index">{{el?.name?.language_ru}}</label>
                </div>
                <div class="modal_toggle" v-for="(item,num) in el.subMenu" :key="num">
                  <input class="cursor-pointer" v-model="item.favourite" :id="num+''+index" type="checkbox">
                  <label class="cursor-pointer" :for="num+''+index">{{item?.name?.language_ru}}</label>
                </div>
              </div>
              <SaveBtn :go_back="go_back" @modalClose="modalShow" :payload="{documents:$store.state.allSection.menu.menuDocument}" api="allSection/favouriteMenu"/>
              <!-- <p>{{all_arr.arrays[0]}}</p> -->
          </div>
      </div>
    </div>
</template>
<script>
export default {
  props: {
    all_arr: {
      type: Object,
      default: () => null,
    },
    go_back:{
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    modalShow() {
      this.$emit("modalShow", false);
    },
  },
};
</script>
<style scoped>
.modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-main {
  max-width: 920px;
  background: white;
  width: 100%;
  height: 100%;
  max-height: 850px;
  border-radius: 12px;
  padding: 50px;
  position: relative;
}

.modal-header i {
  position: absolute;
  font-size: 16px;
  top: 7px;
  right: 7px;
  padding: 5px;
  color: #000000;
  cursor: pointer;
}

.modal-header {
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0;
  padding: 0 !important;
  border-bottom: 0 !important;
  /* margin-bottom: 32px; */
}
.modal-body {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: auto;
}
.toggle {
  display: flex;
  align-items: center;
}
</style>