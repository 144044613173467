<template>
    <div class="save-cancel-btn">
        <button @click="modalShow" class="cancel_btn">отмена</button>
        <button @click="submit" class="save_btn">{{save_label}}</button>
    </div>
</template>
<script>
export default {
  props:{
    go_back:{
      type:Boolean,
      default() {
                return true;
            },
      },
    storeFunction:{
      type: String,
      default() {
                return "";
            },
    },
    api:{
      type: String,
      default() {
                return "";
            },
    },
    save_label:{
      type: String,
      default() {
                return "сохранить";
            },
    },
    payload:{
      type: [String,Object,Number],
      default() {
                return null;
            },
    },
  },
  methods: {
    modalShow() {
      if(this.go_back){
        this.$router.go(-1)
      }else{
        this.$emit("modalClose", false);
      }
    },
    async submit(){
      await this.$emit("submit")
      if(!this.api) return
      await this.$store.dispatch(this.api,this.payload).then((res)=>{
        if((res.status==200)||(res.status==201)){
          if(this.go_back){
            this.$router.go(-1)
            this.$message.success("Success")
          }else{
            this.$router.go()
          }
        } else{
          this.$message.error("Something wrong")
        }
      }).catch(err=>{
        this.$message.error("Something wrong -"+ err)
      })
    }
  },
};
</script>