export const state = () => ({
  user: null
});

export const mutations = {
 
};

export const actions = {

    login(_, payload) {
        return this.$axios({
            withCredentials: true,
            credentials: 'include',
            method: "POST",
            url:"users/login",
            data:payload,
        }).then((res) => {
            return res
        }).catch((err)=>{
            console.log(err);
            return err
        })
    },
    logout() {
        return this.$axios({
            withCredentials: true,
            credentials: 'include',
            method: "POST",
            url:"logout",
        }).then((res) => {
            return res
        }).catch((err)=>{
            console.log(err);
            return err
        })
    },
    user() {
        return this.$axios({
            withCredentials: true,
            credentials: 'include',
            method: "GET",
            url: "user",
        }).then((res) => {
            return res
        }).catch((err)=>{
            console.log(err);
            return err
        })
    },
    // getUser({
    //     commit
    // }){
    //     const access_token  = localStorage.getItem("tokens")
    //     const token_split = access_token && access_token.split(".")
    //     const fields_str = token_split && JSON.parse(atob(token_split[1]))
    //     const user = fields_str && fields_str.user && fields_str.user
    //     commit("setUserId",user)
    // },
    // logout({
    //     commit
    // }) {
    //     commit("setState", {
    //         access: null,
    //         user: null
    //     })
    //     localStorage.removeItem("tokens")
    // }
}