<template>
    <div class="sidebar" @click="dropDown = false">
        <div class="sidebar-wrapper">
           <div class="sidebar-logo" @click.stop="$router.push('/list')">
                <img src="@/assets/image/logo.svg" alt="">
           </div>
           <div class="hr">
                <img src="@/assets/icons/line.svg" alt="">
           </div>
           <div class="sidebar-route">
              <router-link to="/list"> <span><img src="@/assets/icons/side_content.svg" alt=""></span> Список</router-link>
           </div>
        </div>
        <div class="side_user">
            <div class="side_user_name" @click.stop="openDrop">
                <span><img src="@/assets/icons/avatar.svg" alt=""></span>
                <p>Admin</p>
                <div class="profile-logout" v-if="dropDown">
                  <div class="dropdown-input-list" :class="dropDown ? 'open':'closed'" >
                    <h6 @click.stop="chooseDep(item)" v-for="(item, idx) in option" :key="idx" class="dropdown-fixed-item">
                      {{item.name}}
                    </h6>
                  </div>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data(){
    return{
      dropDown: false,
      option: [
        {
          idx: 1,
          name: "Bыход",
          path: "/login"
        }
      ]
    }
  },
  methods:{
    
    openDrop(){
        this.dropDown=!this.dropDown
    },
    async chooseDep(item){
      if(item.idx == 1){
        localStorage.removeItem('tokens')
        this.$router.push('/login')
      }
    },
  }
}
</script>