<template>
  <div :class="'row-'+col">
    <div>
      <div class="file_uploader">
        <label>{{label}}</label>
        <div class="flex">
          <label v-if="!documentUrl.path||document" :class="{required:required&&!document}" for="addUser" class="file_upload">
              <span class="placeholder" v-if="!document">{{placeholder}}</span>
              <span v-else>{{document.name}}</span>
          </label>
          <label v-else for="addUser" class="file_upload">
              <span>{{documentUrl.path|filterurl}}</span>
          </label>
          <input :accept="typeFile" @change="setImage" v-show="false" id="addUser" type="file">
        </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    typeFile: {
      type: String,
      default() {
        return "image/*";
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    documentUrl: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default() {
        return 'Файл документа';
      },
    },
    placeholder: {
      type: String,
      default() {
        return 'Выберите файл';
      },
    },
    col: {
      type: Number,
      default() {
        return 12;
      },
    },
  },
  data() {
    return {
      document: null,
    };
  },
  methods: {
    delPage(){
      this.document = null
      this.$emit("getValue",this.document)
    },
    setImage(e) {
        this.document = e.target.files[0];
        this.$emit("getValue",this.document)
        this.$emit("getRequire",(this.document||this.documentUrl))
    },
  },
  created(){
        let a = (this.document||this.documentUrl) ? true : false
        this.$emit("getRequire",a)
    },
  filters:{
    filterurl(e){
      if(e){
        let newarg = e.split("/")
        return newarg[newarg.length-1]
      }
    }
  }
};
</script>