import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/login'
import store from '@/store'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
  });
};
const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {layout: 'additional', auth: false},
  },
  { path: '*', redirect: '/404' },
  { path: '/', redirect: '/list' },
  {
    path: '/404',
    name: '404',
    component: () => import ('../views/404'),
    meta: {layout: 'main', auth: true},
  },
  {
    path: '/list',
    name: 'list',
    component: () => import ('../views/list'),
    meta: {layout: 'main', auth: true},
  },
  {
    path: '/list/create',
    name: 'list_create',
    component: () => import ('../views/list/create'),
    meta: {layout: 'main', auth: true},
  },
  {
    path: '/list/:id',
    name: 'list_Id',
    component: () => import ('../views/list/edit'),
    meta: {layout: 'main', auth: true},
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const access_token  = localStorage.getItem("tokens")
  const token_split = access_token && access_token.split(".")
  const fields_str = token_split && JSON.parse(atob(token_split[1]))
  const role = fields_str && fields_str.role
  store.state.auth.role = role
  store.state.auth.user = fields_str && fields_str.user
    if (role && to && to.name == "LoginPage") {
      if(role == ('admin')) {
        next({
          path:"list" 
        });
      }
  }else if (!fields_str && to.name != "LoginPage") {
    next({
      name:"LoginPage"
  });
  }else{
    next();
  }
})

export default router
