<template>
    <div>
        <div :class="retrow" v-for="el,index in pages" :key="index">
            <div>
                <label>{{label}} (РУС)</label>
                <input @paste="onPaste" @input="typing" v-if="!textarea" v-model="el.language_ru" type="text" class="personal_contact mb-2" :placeholder="placeholder" :class="{required:(required&&!el.language_ru)}"/>
                <textarea @paste="onPaste" @input="typing" v-else v-model="el.language_ru" type="text" class="personal_contact mb-2" :placeholder="placeholder" :class="{required:(required&&!el.language_ru)}"/>
                <span class="invalid_text" v-if="required && !el.language_ru">Заполните поле</span>
            </div>
            <div>
                <label>{{label}} (UZB)</label>
                <input @paste="onPaste" @input="typing" v-if="!textarea" v-model="el.language_uzlatin" type="text" class="personal_contact mb-2" :placeholder="placeholder" :class="{required:(required&&!el.language_uzlatin)}"/>
                <textarea @paste="onPaste" @input="typing" v-else v-model="el.language_uzlatin" type="text" class="personal_contact mb-2" :placeholder="placeholder" :class="{required:(required&&!el.language_uzlatin)}"/>
                <span class="invalid_text" v-if="required && !el.language_uzlatin">Заполните поле</span>
            </div>
            <div>
                <label>{{label}} (ЎЗБ)</label>
                <input @paste="onPaste" @input="typing" v-if="!textarea" v-model="el.language_uzCyrillic" type="text" class="personal_contact mb-2" :placeholder="placeholder" :class="{required:(required&&!el.language_uzCyrillic)}"/>
                <textarea @paste="onPaste" @input="typing" v-else v-model="el.language_uzCyrillic" type="text" class="personal_contact mb-2" :placeholder="placeholder" :class="{required:(required&&!el.language_uzCyrillic)}"/>
                <span class="invalid_text" v-if="required && !el.language_uzCyrillic">Заполните поле</span>
            </div>
            <div>
                <label>{{label}} (ENG)</label>
                <input @paste="onPaste" @input="typing" v-if="!textarea" v-model="el.language_en" type="text" class="personal_contact mb-2" :placeholder="placeholder" :class="{required:(required&&!el.language_en)}"/>
                <textarea @paste="onPaste" @input="typing" v-else v-model="el.language_en" type="text" class="personal_contact mb-2" :placeholder="placeholder" :class="{required:(required&&!el.language_en)}"/>
                <span class="invalid_text" v-if="required && !el.language_en">Заполните поле</span>
            </div>
            <div class="content-col-6" v-show="multiple">
            </div>
            <div class="content-col-6" v-show="multiple">
                <div class="contact_add del_page">
                    <button class="del" @click="delPage(index)"><img src="@/assets/icons/delete2.svg" alt=""></button>
                </div>
            </div>
        </div>
        <div class="edit_btn my" v-if="multiple">
            <button @click="addPage"><img src="@/assets/icons/plus.svg" alt="" /> Добавить</button>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        label:{
            type: String,
            default() {
                return "";
            },
        },
        placeholder:{
            type: String,
            default() {
                return "";
            },
        },
        name:{
            type: String,
            default() {
                return "text";
            },
        },
        updateData:{
            type: [Array,Object,String],
            default() {
                return null;
            },
        },
        multiple:{
            type: Boolean,
            default() {
                return false;
            },
        },
        required:{
            type: Boolean,
            default() {
                return false;
            },
        },
        textarea:{
            type: Boolean,
            default() {
                return false;
            },
        },
        marginTop:{
            type: Boolean,
            default() {
                return true;
            },
        },
        col:{
            type: Number,
            default() {
                return 12;
            },
        },
        name:{
            type: String,
            default() {
                return 'text';
            },
        },
    },
    data(){
        return{
            pages:[
                {
                    language_uzlatin: '',
                    language_uzCyrillic: '',
                    language_ru: '',
                    language_en: '',
                }
            ],
            page: {
                    language_uzlatin: '',
                    language_uzCyrillic: '',
                    language_ru: '',
                    language_en: '',
                    },
            
        }
    },
    computed: {
        retrow() {
                if(this.marginTop !== false) {
                    return 'row-'+this.col + ' mt'
            } else return 'row-'+this.col
    },
    },
    methods:{
        addPage(){
            this.pages.push({...this.page})
        },
        delPage(e){
            this.pages.splice(e,1)
        },
        onPaste(){
            this.typing()
        },
        typing(){
            this.$emit("getValue",this.pages)
            this.pages.forEach(el => {
                this.$emit("getRequire",(!!el.language_uzCyrillic&&!!el.language_uzlatin&&!!el.language_ru&&!!el.language_en))
            });
        }
    },
    created(){
        let lastRoute = this.$route.path.split("/")[this.$route.path.split("/").length-1]
        if(this.$route.params.id){
            this.updateData?.find(el=>{
                    el._id == this.$route.params.id ?(this.pages[0] = (el?.[this.name])):(this.pages[0] = (this.updateData[0]?.[this.name]));
            })
        } else if((this.$route.path=='/content/contact')||(this.$route.path=='/content/contacts')){
            this.pages[0] = this.updateData?this.updateData[this.name]:[]
        } else if(lastRoute != 'create'){
            
            if(!this.multiple&&this.updateData&&this.updateData[0]&&this.updateData[0][this.name]){
                this.pages[0] = this.updateData&&this.updateData[0]&&this.updateData[0][this.name]
            }else{
                this.pages = this.updateData
            }
        }
        this.pages?.forEach(el => {
            el && this.$emit("getRequire",(!!el.language_uzCyrillic&&!!el.language_uzlatin&&!!el.language_ru&&!!el.language_en))
        });
    },
}
</script>