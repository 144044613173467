var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"photography",class:{'my-0':_vm.mt0}},[(!_vm.top)?_c('div',[_vm._m(0),_c('span',{staticClass:"attention_content"},[_vm._v("Важно!")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.top)?_c('div',{staticClass:"edit_btn my"},[_c('label',{class:{'mb-3':_vm.top},attrs:{"for":"upl_img"}},[_c('img',{attrs:{"src":require("@/assets/icons/plus.svg"),"alt":""}}),_vm._v(" Добавить изображение ")]),_c('input',{attrs:{"type":"file","id":"upl_img","accept":"image/*"},on:{"change":_vm.setImage}})]):_vm._e(),_c('div',{staticClass:"row-4 row-2_1280"},[_vm._l((_vm.imgs),function(el,index){return _c('div',{key:index,staticClass:"photography_img"},[_c('img',{attrs:{"src":_vm.imgs[index].path,"alt":""}}),_c('div',{staticClass:"down_del"},[_c('button',{on:{"click":function($event){return _vm.downlaod(_vm.imgs[index].path)}}},[_c('img',{attrs:{"src":require("@/assets/icons/download.svg"),"alt":""}})]),_c('button',{on:{"click":function($event){return _vm.removeO(index)}}},[_c('img',{attrs:{"src":require("@/assets/icons/delete.svg"),"alt":""}})])])])}),_vm._l((_vm.new_images),function(el,idx){return _c('div',{key:'index-'+idx,staticClass:"photography_img"},[_c('img',{attrs:{"src":_vm.new_images[idx],"alt":""}}),_c('div',{staticClass:"down_del"},[_vm._m(5,true),_c('button',{on:{"click":function($event){return _vm.removeN(idx)}}},[_c('img',{attrs:{"src":require("@/assets/icons/delete.svg"),"alt":""}})])])])})],2),(!_vm.top)?_c('div',{staticClass:"edit_btn"},[_vm._m(6),_c('input',{attrs:{"type":"file","id":"upl_img","accept":"image/*"},on:{"change":_vm.setImage}})]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"personal_date_main"},[_c('p',{staticClass:"gray_main_title"},[_vm._v("Фотографии")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_title_name"},[_c('span',[_vm._v("Ширина:")]),_c('p',[_vm._v("800 px")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_title_name"},[_c('span',[_vm._v("Высота:")]),_c('p',[_vm._v("600 px")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_title_name"},[_c('span',[_vm._v("Формат:")]),_c('p',[_vm._v("JPG, PNG, JPEG")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_title_name"},[_c('span',[_vm._v("Размер файла:")]),_c('p',[_vm._v("Не более 5 МБ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{attrs:{"title":"Невозможно скачать, изображение недоступно на сервере","disabled":""}},[_c('img',{attrs:{"src":require("@/assets/icons/download.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"upl_img"}},[_c('img',{attrs:{"src":require("@/assets/icons/plus.svg"),"alt":""}}),_vm._v(" Добавить изображение ")])
}]

export { render, staticRenderFns }