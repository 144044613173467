<template>
    <div class="login">
        <div class="login-wrapper">
            <div class="login-part">
                <div class="login-part-content">
                    <div class="login-part-title">
                        <span>O‘zbekiston Respublikasi Madaniyat vazirligi</span>
                    </div>
                </div>
                <div class="login-form">
                    <div class="login-inputs">
                        <div class="login-input">
                            <label for="login">Login</label>
                            <input v-model.trim="login" id="login" placeholder="Loginni kiriting" type="text">
                            <span ><img src="@/assets/icons/human.svg" alt=""></span>   
                        </div>
                        <div class="login-password">
                            <label for="password">Parol</label>
                            <input @keydown.enter="loginUser" v-model.trim="password" id="password" placeholder="Parolni kiriting" :type="password_show?'password':'text'">
                            <span @click="password_show = !password_show"><img v-if="password_show" src="@/assets/icons/eye.svg" alt=""><img v-else src="@/assets/icons/hideEyeLogin.svg" alt=""></span>
                        </div>
                        <button @click="loginUser">KIRISH</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            password_show: true,
            login: null,
            password: null,
        }
    },
    methods:{
        async loginUser() {
            await this.$store
                .dispatch("auth/login", {login: this.login, password:this.password}).then((res)=>{
                    if(res && res.data){
                        localStorage.setItem('tokens',(res?.data?.result?.token))
                        this.$router.push({path:"/list"})  
                    }else{
                        this.$message.error("Login yoki parol noto‘g‘ri")  
                    }
                })
            },
        
    },
    mounted(){
    }
}
</script>