<template>
    <div class="photography" :class="{'my-0':mt0}">
      <div v-if="!top">
        <div class="personal_date_main">
          <p class="gray_main_title">Фотографии</p>
        </div>
        <span class="attention_content">Важно!</span>
        
        <div class="content_title_name">
          <span>Ширина:</span>
          <p>800 px</p>
        </div>
        <div class="content_title_name">
          <span>Высота:</span>
          <p>600 px</p>
        </div>
        <div class="content_title_name">
          <span>Формат:</span>
          <p>JPG, PNG, JPEG</p>
        </div>
        <div class="content_title_name">
          <span>Размер файла:</span>
          <p>Не более 5 МБ</p>
        </div>
      </div>
        <div class="edit_btn my" v-if="top">
            <label for="upl_img" :class="{'mb-3':top}"><img src="@/assets/icons/plus.svg" alt="" /> Добавить изображение </label>
            <input type="file" id="upl_img" accept="image/*" @change="setImage">
        </div>
        <div class="row-4 row-2_1280">
            <div class="photography_img" v-for="el,index in imgs" :key="index">
                <img :src="imgs[index].path" alt="">
                <div class="down_del"><button @click="downlaod(imgs[index].path)"><img src="@/assets/icons/download.svg" alt=""></button><button @click="removeO(index)"><img src="@/assets/icons/delete.svg" alt=""></button></div>
            </div>
            <div class="photography_img" v-for="el,idx in new_images" :key="'index-'+idx">
                <img :src="new_images[idx]" alt="">
                <div class="down_del"><button title="Невозможно скачать, изображение недоступно на сервере" disabled><img src="@/assets/icons/download.svg" alt=""></button><button @click="removeN(idx)"><img src="@/assets/icons/delete.svg" alt=""></button></div>
            </div>
        </div>
        <div class="edit_btn" v-if="!top">
            <label for="upl_img"><img src="@/assets/icons/plus.svg" alt="" /> Добавить изображение </label>
            <input type="file" id="upl_img" accept="image/*" @change="setImage">
        </div>
    </div>
</template>
<script>
export default {
  data(){
    return{
      imgs:this.img,
      images:[],
      new_images:[]
    }
  },
  props:{
    top:{
      type: Boolean,
      default: () => false,
    },
    img:{
      type: Array,
      default: () => [],
    },
    mt0:{
      type: Boolean,
      default: () => false,
    },
    fileSize:{
        type: Number,
        default() {
            return 5;
        },
    },
  },
  methods:{
    removeN(e){
      this.images.splice(e,1)
      this.new_images.splice(e,1)
      this.$emit("getValue",this.images)
    },
    removeO(e){
      this.imgs.splice(e,1)
      this.$emit("getValueOld",this.imgs)
    },
      
    setImage(e) {
      if((e.target.files[0])?.size>(1048576*this.fileSize)){
          this.$message.error(`Размер файла превышает ${this.fileSize} МБ, пожалуйста, загрузите изображение меньшего размера или сожмите его.`)
      }else{
        this.new_images.push(URL.createObjectURL(e.target.files[0]))
        this.images.push(e.target.files[0])
        this.$emit("getValue",this.images)
      }
      // this.form.icon = e.target.files[0]
    },
    downlaod(payload) {
      const apiUrl = payload
      return this.$axios({
          method: "GET",
          url: apiUrl,
          responseType: 'blob'
      }).then(response => {
          var file = (new Blob([response.data]))
          var fileURL = window.URL.createObjectURL(new Blob([file]))
          var fileLink = document.createElement("a")
          fileLink.href = fileURL
          var fileType = payload && payload.split(".")[payload.split(".").length-1]
          var fileName = payload && payload.split(".")[payload.split(".").length-2]
          fileLink.setAttribute("download", `${fileName}.${fileType}`)
          document.body.appendChild(fileLink)
          fileLink.click()
          return "fileUploaded"
        }).catch((err)=>{
          return err
      })
    },
  }
}
</script>