<template>
  <div class="content_nav">
    <p class="content_nav_title" v-if="card?.category == 'section'">
      Раздел - {{ card.name.language_ru }}
      <span v-if="pen" @click="showModal"
        ><img src="@/assets/icons/pen.svg" alt=""
      /></span>
    </p>
    <p
      class="content_nav_title"
      v-if="card?.category == 'home' || card?.category == 'page'"
    >
      Страница без раздела
    </p>
    <p class="content_nav_title" v-if="card.title">
      {{ card.title }}
      <span v-if="pen" @click="showModal"
        ><img src="@/assets/icons/pen.svg" alt=""
      /></span>
    </p>
    <p class="def_p" v-if="pen && !card.array.length">
      Сохраняйте часто используемые страницы для быстрого доступа
    </p>
    <div class="row-4 row-2_1280">
      <div
        v-show="((card?.category == 'page')||(card?.category == 'home')) && card?.name"
        ref="active_link"
        @click="routeLinks(card)"
        class="content_card"
      >
        <img v-if="card.icon" :src="card.icon" alt="" />
        <img v-else src="@/assets/icons/file.svg" alt="" />
        <p>{{ card?.name?.language_ru }}</p>
      </div>
      <div
        v-show="card?.category == 'section'"
        ref="active_link"
        @click="routeLinks(el, index)"
        class="content_card"
        v-for="(el, index) in card.subMenu"
        :key="index"
      >
        <img v-if="el.icon" :src="el.icon" alt="" />
        <img v-else src="@/assets/icons/file.svg" alt="" />
        <p>{{ el?.name?.language_ru }}</p>
      </div>
      <div
        v-show="card.array"
        ref="active_link"
        @click="routeLinks(el, index)"
        class="content_card"
        :class="{editable_card:(!!api)}"
        v-for="(el, index) in card.array"
        :key="index"
      >
        <img v-if="el.icon" :src="el.icon" alt="" />
        <img v-else src="@/assets/icons/file.svg" alt="" />
        <p v-if="!!api" class="line_2">{{ el.title?.language_ru || el?.name?.language_ru }}</p>
        <p v-else>{{ el.title || el?.name?.language_ru }}</p>
        <div v-if="!!api" class="editable_card_icons">
            <button @click.stop="hideSection(el)"><img v-if="!el.visible" src="@/assets/icons/hide_eye.svg" alt=""><img v-else src="@/assets/icons/eye2.svg" alt=""></button>
            <button @click.stop="delSection(el._id)"><img src="@/assets/icons/delete.svg" alt=""></button>
        </div>
      </div>
    </div>
    <hr v-if="(card.name || card.title) && last" />
    <Modal
      :go_back="false"
      v-if="show"
      @modalShow="modal_show($event)"
      :all_arr="datas"
    />
          <el-dialog class="confirm-modal"
             center
             :modal-append-to-body="false"
             :append-to-body="true"
             :visible="isVisible"
             @close="close"
             v-bind="$attrs"
             >
            <slot>
              <span>Вы уверены, что хотите удалить?</span>
            </slot>
            <span slot="footer" class="dialog-footer">
              <el-button id="save-button" type="success" @click.native="confirm">
                Да
              </el-button>
              <el-button id="close-button" type="danger" @click.native="close">
                Нет
              </el-button>
          </span>
          </el-dialog>
  </div>
</template>
<script>
import Modal from "../popup/modal.vue";
export default {
  components: {
    Modal,
  },
  data() {
    return {
      show: false,
      isVisible:false,
      idx: null
    };
  },
  props: {
    card: {
      type: [Array, String, Object],
      default: () => null,
    },
    datas: {
      type: Object,
      default: () => null,
    },
    api: {
      type: String,
      default: () => '',
    },
    pen: {
      type: Boolean,
      default: () => false,
    },
    pen2: {
      type: Boolean,
      default: () => false,
    },
    last: {
      type: Boolean,
      default: () => true,
    },
  },
  methods: {
    modal_show(show) {
      this.show = show;
    },
    showModal() {
      this.show = true;
    },
    routeLinks(el, index) {
      if (index != undefined) {
        this.$refs.active_link[index].classList.add("active");
      } else {
        this.$refs.active_link.classList.add("active");
      }
      setTimeout(() => {
        if (this.$route.path == "/content") {
          this.$router.push("/content" + (el?.url || el?.link));
        } else {
          if(el.route){
            this.$router.push({path:(el?.url || el?.path || el?.link),query:{link:el.route}});
          }else{
            this.$router.push(el?.url || el?.path || el?.link);
          }
        }
      }, 100);
    },
    async hideSection(el){
        let f = new FormData()
        if(el.visible&&el.homeVisible){
          f.append('homeVisible',!el.homeVisible)
        }
        f.append('visible',!el.visible)
        await this.$axios.put(this.api+'/'+el._id,f)
        this.$router.go()
    },
    confirm(){
      this.$axios.delete(this.api+'/'+this.idx).then(res=>{
        if(res)  {
          this.isVisible = false
          this.$router.go()
        }
      })
    },
    close(){
      this.isVisible = false
    },
    delSection(idx){
      this.isVisible = true
      this.idx = idx
    },
  },
};
</script>