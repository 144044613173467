import axios from "axios"
import router from "../router"

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_ENV_VARIABLE || "https://culture.com.uz/api/admin/",
    // baseURL: "http://192.168.10.16:2007/api/v1/",
    withCredentials: true,
    credentials: "include",
    timeout: 60000,
    headers: {
        "Access-Control-Allow-Credentials": "true",
        // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': true,
    
        // 'Access-Control-Allow-Headers':
        // 'Origin, X-Requested-With, Content-Type, Accept'
        "Content-type": "application/json",
        // "Accept": "application/json",
        // "Access-Control-Allow-Origin": "http://localhost:8080/",
        // "Access-Control-Allow-Methods": "OPTIONS",
        // "Access-Control-Allow-Headers": "true",
    },
})

// const isDev = process.env.NODE_ENV !== "production"

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers["Accept"] = 'application/json'
        config.headers["Content-Type"] = 'application/json'
        config.headers["Access-Control-Allow-Origin"] = '*'
        config.headers["Access-Control-Allow-Methods"] = 'OPTIONS'
        config.headers["Access-Control-Allow-Headers"] = 'Content-Type, Authorization'
        config.headers["Access-Control-Allow-Credentials"] = true
        const storageToken = localStorage.getItem('tokens')
        config.headers["authorization"] = storageToken ?
            `${storageToken}` :
            ""


        return config
    },

    (error) => {
        return Promise.resolve(error)
    }
)

axiosInstance.interceptors.response.use(
    function (response) {
        return response || {}
    },
    async function (error) {
        const statusCode = (error.response || {}).status || -1

        if ((statusCode == 401)||(statusCode == 403)||(statusCode == -1)) {
            delete axiosInstance.defaults.headers.common["Authorization"]
            localStorage.removeItem('tokens')
                router.push({
                    name: "LoginPage"
                })
            return Promise.reject(error)
        }
        return Promise.reject(error)
    }
)

export default axiosInstance