<template>
  <div class="main-layout" v-if="true">
    <SideBar/>
    <div class="page">
      <router-view/>
    </div>
  </div>
</template>

<script>
import SideBar from '../home/Sidebar.vue'
// import router from '@/router'

export default {
  name: 'MainLayout',
  data(){
    return{
      loading: false
      // timeoutInMS : 1800000,
      // timeoutId: null,
    }
  },
  components: {
    SideBar
  },
  methods:{
    // async getUser(){
    //   this.loading = false
    //   await this.$axios.get('users/profile').then((res=>{
    //     this.$store.state.auth.user = res?.data?.user
    //     if(this.$store.state.auth.user.role!='superAdmin'){
    //       router.options.routes.forEach(el=>{
    //         if(this.$store.state.auth.user.permissions.content.allowed == false){
    //           (el.path.split('/')[1]=='content')?(el.meta && (el.meta.auth=false)):'';
    //         }
    //         if(this.$store.state.auth.user.permissions.seo.allowed == false){
    //           (el.path.split('/')[1]=='seo')?(el.meta && (el.meta.auth=false)):'';
    //         }
    //         if(this.$store.state.auth.user.permissions.mail.allowed == false){
    //           (el.path.split('/')[1]=='mail')?(el.meta && (el.meta.auth=false)):'';
    //         }else{
    //           if(this.$store.state.auth.user.permissions.mail.allowed == false){
    //             ((el.path.split('/')[1]=='mail')&&(el.path.split('/')[2]=='feedback'))?(el.meta && (el.meta.auth=false)):'';
    //           }
    //         }
    //         if(this.$store.state.auth.user.permissions.settings.allowed == false){
    //           (el.path.split('/')[1]=='settings')?(el.meta && (el.meta.auth=false)):'';
    //         }else{
    //           if(this.$store.state.auth.user.permissions.settings.additional.allowed == false){
    //             (el.path.split('/')[2]=='any')?(el.meta && (el.meta.auth=false)):'';
    //           }
    //           if(this.$store.state.auth.user.permissions.settings.footer.allowed == false){
    //             (el.path=='/settings/footer')?(el.meta && (el.meta.auth=false)):'';
    //           }
    //           if(this.$store.state.auth.user.permissions.settings.header.allowed == false){
    //             (el.path.split('/')[2]=='header')?(el.meta && (el.meta.auth=false)):'';
    //           }
    //           if(this.$store.state.auth.user.permissions.settings.sitemap.allowed == false){
    //             (el.path.split('/')[2]=='sitemap')?(el.meta && (el.meta.auth=false)):'';
    //           }
    //           if(this.$store.state.auth.user.permissions.settings.users.allowed == false){
    //             (el.path.split('/')[2]=='user')?(el.meta && (el.meta.auth=false)):'';
    //           }
    //         }
    //       })
    //     }
    //     this.loading = true
    //   }))
    // }
    // setupTimers () {
    //     document.addEventListener("keypress", this.resetTimer, false);
    //     document.addEventListener("mousemove", this.resetTimer, false);
    //     document.addEventListener("mousedown", this.resetTimer, false);
    //     document.addEventListener("touchmove", this.resetTimer, false);
        
    //     this.startTimer();
    // },
    // startTimer() { 
    //     this.timeoutId = setTimeout(() => {this.handleInactive()}, this.timeoutInMS);
    // },
    // resetTimer() { 
    //     clearTimeout(this.timeoutId);
    //     this.startTimer();
    // },
    // handleInactive() {
    //     this.$router.push("/")
    // }
  },
  async mounted(){
    this.loading = false
    // await this.$axios.get('users/profile').then((res=>{
    //     this.$store.state.auth.user = res?.data?.user
    //     window.sessionStorage.user = JSON.stringify(res?.data?.user)
    //     this.loading = true
    // }))
    this.loading = true
  }
}
  
  
</script>

<style>

</style>