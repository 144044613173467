<template>
    <div class="header">
        <div class="header-wrapper">
            <div class="header_main">
                <p>{{header_title}}  </p>
                <!-- <input placeholder="Поиск..." type="text"> -->
            </div>
            <div class="header_breadcrumb">
                <small :style="bread_crumb?'cursor:pointer':''" @click="bread_crumb ? $router.push(main_crumb?.path): ''" class="first">{{main_crumb?.title}}</small>
                <span v-if="bread_crumb"></span>
                <small v-if="bread_crumb" :style="userName?'cursor:pointer':''" @click="userName ? $router.push(bread_crumb?.path): ''" :class="{first:userName}">{{bread_crumb?.title}}</small>
                <span v-if="bread_crumb2"></span>
                <small v-if="bread_crumb2" :style="userName?'cursor:pointer':''" @click="userName ? $router.push(bread_crumb2?.path): ''" :class="{first:userName}">{{bread_crumb2?.title}}</small>
                <span v-if="userName"></span>
                <small v-if="userName">{{userName}}</small>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        header_title:{
          type: [String,Number],
          default: () => null,
        },
        main_crumb: {
          type: [String, Object],
          default: () => null,
        },
        bread_crumb: {
          type: [String, Object],
          default: () => null,
        },
        bread_crumb2: {
          type: [String, Object],
          default: () => null,
        },
        userName: {
          type: [String,Number],
          default: () => null,
        },
    }
}
</script>