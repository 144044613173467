import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import Header from "./components/home/Header.vue";
import Content from "./components/content/card.vue";
import SaveBtn from '@/components/content/saveCancelBtn.vue'
import Photography from "@/components/content/photos.vue";
import Pagination from './components/content/pagination.vue'
import multipleFile2 from './components/inputs/multipleFile2.vue'
import singleFile from './components/inputs/singleFile.vue'
import locale from 'element-ui/lib/locale/lang/ru-RU'
import Col from './components/inputs/col.vue'
import preLoader from './components/layouts/preLoader.vue'
import editedby from './components/content/editedBy.vue'

import ElementUI from "element-ui"
import CKEditor from "ckeditor4-vue"
import VueMask from "v-mask"

Vue.use( CKEditor );
Vue.use(ElementUI, {locale});
Vue.use(VueMask)

Vue.component('singleFile', singleFile)
Vue.component('editedby', editedby)
Vue.component('preLoader', preLoader)
Vue.component('Header', Header)
Vue.component('Content', Content)
Vue.component('SaveBtn', SaveBtn)
Vue.component('Photography', Photography)
Vue.component('Pagination', Pagination)
Vue.component('MultipleFile2', multipleFile2)

Vue.component('Col', Col)
Vue.config.productionTip = false
import 'element-ui/lib/theme-chalk/index.css';

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
