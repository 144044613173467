<template>
    <div class="editedBy">
        <div class="row-12 div" v-if="!!data">
            <div class="editedby_row">
                <div>
                    <small>Последние изменения:</small>
                    <p>{{data?.editedBy?.name||data?.editedBy?.login||'-'}}</p>
                </div>
                <div>
                    <div v-if="editable" class="modal_toggle">
                        <div class="toggle">
                            <label class="switch">
                            <input type="checkbox" @click="hideShow" v-model="data.visible">
                            <span class="slider round"></span>
                            </label>
                        </div>
                        <p>Показать на сайте</p>
                    </div>
                </div>
            </div>
            <div class="editedby_row date_ed">
                <div>
                    <small>Автор изменений:</small>
                    <p>{{(data.updatedAt)|dateFilter}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        editable:{
            type: Boolean,
            default: () => false,
        },
        data:{
            type: Object,
            default: () => {},
        },
    },
    methods:{
        hideShow(){
            this.$emit('getShow',this.data.visible)
        }
    },
    filters:{
        dateFilter(e){
            if(!!e){
                let date = new Date(e)
                let newdate = ('0'+date.getDate()).slice(-2)+'.'+('0'+(date.getMonth()+1)).slice(-2)+'.'+date.getFullYear()
                let newtime = ('0'+date.getHours()).slice(-2)+':'+('0'+date.getMinutes()).slice(-2)
                return  newdate+' '+newtime
            }else{
                return '-'
            }
        }
    }
}
</script>