
export const state = () => ({
  });
  
  export const mutations = {
  };
  
  export const actions = {
    create(_, payload) {
      return this.$axios({
        method: "POST",
        url: "contents/new",
        data: payload
      }).then((res) => {
        return res
      }).catch((err) => {
        console.log(err);
        return err
      })
    },
    update(_, payload) {
      return this.$axios({
        method: "PUT",
        url: "contents/"+payload.content_id,
        data: payload
      }).then((res) => {
        return res
      }).catch((err) => {
        console.log(err);
        return err
      })
    },
  }