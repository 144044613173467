<template>
  <div id="app">
    <component :is="layout">
    </component>
  </div>
</template>
<script>
import AdditionalLayout from './components/layouts/AdditionalLayout.vue'
import MainLayout from './components/layouts/MainLayout.vue'
  
export default {
  name: 'app',
  components: {
    AdditionalLayout, MainLayout
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || 'additional') + '-layout'
    }
  },
}
</script>

<style lang="scss">
@import './src/assets/scss/styles.scss'

</style>
