<template>
  <div class="additional-layout">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'additional-layout'
}
</script>