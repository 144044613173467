<template>
  <div :class="'row-'+col">
    <div class="file_uploader mt">
      <label>{{label}} ({{typeFile}})</label>
      <div v-for="(el, index) in oldValue" :key="index" class="multiple_image mb-2">
        <label class="file_upload mt-1">
          <span class="el">{{ el?.path|filterurl }}</span>
        </label>
        <div class="contact_add del_page">
          <button class="del" @click="delPage(index)">
            <img src="@/assets/icons/delete2.svg" alt="" />
          </button>
        </div>
      </div>
      <div v-for="(el, index) in images" :key="'key-'+index" class="multiple_image mb-2">
        <label :class="{required:(required&&images.length<1)}" class="file_upload mt-1">
          <span class="el" v-if="el">{{ el.name }}</span>
        </label>
        <div class="contact_add del_page" v-show="images.length > 0">
          <button class="del" @click="delPage2(index)">
            <img src="@/assets/icons/delete2.svg" alt="" />
          </button>
        </div>
      </div>
      <div class="multiple_image mb-2">
        <label :class="{required:(required&&images.length<1)}" :for="idx+'addUser1'" class="file_upload mt-1">
          <span class="placeholder">{{placeholder}}</span>
        </label>
        <input
          :accept="typeFile"
          @change="setImage"
          v-show="false"
          :id="idx+'addUser1'"
          type="file"
        />
      </div>
      <span class="invalid_text" v-if="required && images.length<1">Заполните поле</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    typeFile: {
      type: String,
      default() {
        return "image/*";
      },
    },
    multiple: {
      type: Boolean,
      default() {
        return true;
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    values: {
      type: Array,
      default() {
        return [];
      },
    },
    idx: {
      type: Number,
      default() {
        return 0;
      },
    },
    label: {
      type: String,
      default() {
        return 'Дополнительные изображения';
      },
    },
    placeholder: {
      type: String,
      default() {
        return 'Выберите файл';
      },
    },
    col: {
      type: Number,
      default() {
        return 12;
      },
    },
  },
  data() {
    return {
      images: [],
      oldValue: this.values
    };
  },
  methods: {
    delPage(e) {
      this.oldValue.splice(e,1)
      this.$emit("delOldValue",this.oldValue)
    },
    delPage2(e) {
      this.images.splice(e,1)
      this.$emit("getValue",this.images)
    },
    setImage(e) {
      this.images.unshift((e.target.files[0]))
      this.$emit("getValue",this.images)
    },
  },
  filters:{
    filterurl(e){
      if(e){
        let newarg = e.split("/")
        return newarg[newarg.length-1]
      }
    }
  }
};
</script>